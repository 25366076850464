import {
  FormControl,
  Box,
  Container,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import styles from "../Account.module.css";
import { MediumText } from "../../../../components/typography";
import { useAppDispatch, useAppSelector } from "app/hooks";
import HolzfinderFormInput from "components/inputs/HolzfinderFormInput";
import { selectCountries, updateCity, updateStreet, updateStreetNumber, updateZipCode, updateCountryId, selectCustomer, selectErrors, customerSettings, updatePhone } from "features/customer/customerSlice";
import { CustomerCountriesInterface, CustomerCountryInterface } from "interfaces/CustomerCountriesInterface";
import { useEffect, useState } from "react";
import { mapCustomerCountries } from "features/customer/mapCustomerCountries";


export default function AccountSettingsForm() {
  const dispatch = useAppDispatch();
  const customerData = useAppSelector(selectCustomer);
  const countries = useAppSelector(selectCountries)
  useEffect(() => {
    dispatch(customerSettings())
  }, [])
  function getCountryName() {
    if (!customerData.country_id || !countries) {
      return "Land";
    }
    const selectedCountry = countries.find(
      (el: CustomerCountryInterface) => el.id === customerData.country_id
    );
    // debugger

    return selectedCountry ? selectedCountry.name : "Land";
  }


  const errors: any = useAppSelector(selectErrors) || {};



  return (
    <Box>
      <Box>
        <Typography
          fontWeight={"bold"}
          variant="h5"
          sx={{
            mt: 2,

            color: "#072D26",
          }}
        >
          ANSCHRIFT
        </Typography>
      </Box>

      <FormControl
        sx={{
          width: { lg: "60%", md: "80%", xs: "100%" },
          pt: 2, gap: 0.5
        }}
        className={styles.form}
      >
        <MediumText>Straße*</MediumText>
        <TextField
          type="text"
          placeholder="Straße"
          onChange={(event) => {
            dispatch(updateStreet(event.target.value))
          }}
          name={"street"}
          required
          value={customerData.street}
          InputProps={{
            className: styles.input
          }}

        />

        <MediumText>Hausnummer*</MediumText>
        <TextField
          type="tel"
          placeholder="Hausnummer"
          InputProps={{
            className: styles.input
          }}
          required
          onChange={(event) => { dispatch(updateStreetNumber(event.target.value)) }}
          name={"house_number"}
          value={customerData.street_number}
        />

        <MediumText>PLZ*</MediumText>
        <TextField
          error={!!errors["zip_code"] as boolean}
          helperText={
            !!errors["zip_code"]
              ? (errors["zip_code"].join(", ") as string)
              : ""
          }
          required
          type="tel"
          placeholder="PLZ"
          onChange={(event) => { dispatch(updateZipCode(event.target.value)) }}
          name={"postal_code"}
          value={customerData.zip_code}
          InputProps={{
            className: styles.input
          }}
        />

        <MediumText>Ort*</MediumText>
        <TextField
          required
          type="text"
          placeholder="Ort"
          onChange={(event) => {
            const inputValue = event.target.value;
            const nonNumericInput = inputValue.replace(/[0-9]/g, '');
            dispatch(updateCity(nonNumericInput));
          }}
          name="city"
          value={customerData.city}
          InputProps={{
            className: styles.input
          }}
        />


        <MediumText>Telefon</MediumText>
        <TextField

          type="tel"
          placeholder="Telefon"
          onChange={(event) => { dispatch(updatePhone(event.target.value)) }}
          name={"phone"}
          value={customerData.phone}
          InputProps={{
            className: styles.input
          }}
        />

        <MediumText>Land*</MediumText>
        <Autocomplete

          options={countries || []}
          groupBy={(option: any) => option.firstLetter}
          getOptionLabel={(option: any) => option.label}
          renderInput={(params) =>
            <TextField
              {...params}
              placeholder={getCountryName()}
              sx={{ ".MuiInputBase-root": { borderRadius: "26px" } }}
            />
          }
          onChange={(event, value) => {
            if (value) {
              dispatch(updateCountryId(value.id))
            }
          }}
        />
        {/* <MediumText>Land</MediumText> */}
        {/* <Select type="text" placeholder="Land" className={styles.input} onChange={handleChange} name={"town"}>
        </Select> */}
        {/* <Link
          color={"#000000"}
          fontWeight={"bold"}
          sx={{ mt: 1, mb: 1, whiteSpace: "pre" }}
        >
          + ABWEICHENDE RECHNUNGSADRESSE
        </Link> */}
      </FormControl>
    </Box>
  );
}